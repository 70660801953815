import styled from "styled-components";
import {CardLink} from "../../components";
import React from "react";
import Page from "../Page"
import PageGrid from "../../components/PageGrid";
import {theme} from "../../theme";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheckCircle, faExclamationCircle, faBan} from "@fortawesome/pro-regular-svg-icons";
import Accordion from "../../components/Accordion";
import * as DataService from "../../services/data.service";
import * as SessionService from "../../services/session.service";
import * as ScoreSetService from "../../services/scoreset.service";
import {withTranslation} from "react-i18next";
import * as QuestionsService from "../../services/questions.service";

const ResultPageElm = styled(Page)`
  .navigation {
    margin-top: 6rem;
  }
  
  .svg-inline--fa {
    width: 5rem;
    height: 5rem;
    margin: 0 auto;
  }
  
  .checklist {
      list-style-type: none;
      padding-left: 0;
  
    li {
      margin: 1.5rem 0;
 
        .svg-inline--fa {
           height: 2rem;
           width: 2rem;
           margin-right: .75rem;
        }
      strong {
       margin-right: .5rem;
      }
    }
  }
  
  .contact {
    margin-top:25px;
    p {
      color: ${theme.primaryDark};
      font-weight: 700;
    }
    
    &__partner {
      background-color: ${theme.primaryLight};
      color: ${theme.primaryDark};
      border-radius: ${theme.borderRadius};
      padding: 2rem;
      margin: 1.5rem 0;
      
      &__title {
        margin-top: 0;
        font-weight: 700;
      }
      
      &__address, &__phone, &__email {
          max-width: 400px;
          margin: 1.5rem auto 1.25rem auto;
          
        p, a {
          font-size: 1.5rem;
          margin: .5rem 0;
        }
        
        a {
          text-decoration: underline;
        }
      }
      
      &__email {
        margin-top: 1rem;
      }
    }
  }
`;

class ResultPage extends React.Component {
    constructor(props) {
        super(props);

        const {t} = this.props;
        this.state = {
            labor_law_countryOpen: false,
            tax_countryOpen: false,
            social_insurance_countryOpen: false,
            potential_trapsOpen: false,
            context: props.context,
            scoreSet: {},
            answers: props.answers,
            organisations: [],
            resultIcon: faExclamationCircle,
            resultTitle: t("pages.results.title1"),
            resultText: t("pages.results.description1")
        };

        this.state.context.postalcode = 2950; // todo REMOVE
        this._getOrganisations(this.state.context.postalcode);
        this.getScoreSet(props.scoreSet.id, props.i18n.language);
    }

    getScoreSet(id, language = 'nl') {
        ScoreSetService.getScoreset(language, id).then((res) => this.parseScoreSet(res));
    }

    parseScoreSet(scoreset) {
        if (scoreset.scoreSet.id) {
            this.setState(
                {
                    scoreSet: scoreset.scoreSet
                }
            );
            //TODO: PostSession
            this.postSession();
        }
    }

    postSession() {
        const session = {
            "email": "anonymous@fairmobilitytool.eu",
            "name": "anonieme",
            "firstName": "sessie",
            "laborLawCountryTC": this.state.scoreSet.labor_law_country.name,
            "socialInsuranceCountryTC": this.state.scoreSet.social_insurance_country.name,
            "taxCountryTC": this.state.scoreSet.tax_country.name,
            "jobsCount": 1,
            "language": this.props.i18n.language,
            "answers": [
                {
                    "ids": this.state.answers,
                    "scoreSet": this.state.scoreSet.id
                }
            ]
        };
        SessionService.postSession(session).then((resp) => console.log(resp));
    }

    componentDidMount() {
        this.validateGlobal();
    }

    validateScoreSet(scoreSet) {
        switch (scoreSet) {
            case 'labor_law_country':
                return this.state.scoreSet['labor_law_country'] ? this.state.scoreSet['labor_law_country'].id === this.state.context['country'] : false;
            case 'tax_country':
                return this.state.scoreSet['tax_country'] ? this.state.scoreSet['tax_country'].id === this.state.context['taxes'] : false;
            case 'social_insurance_country':
                return this.state.scoreSet['social_insurance_country'] ? this.state.scoreSet['social_insurance_country'].id === this.state.context['social_security'] : false;
            default:
                return false;
        }
    }

    toggleState(key) {
        switch (key) {
            case 'labor_law_countryOpen':
                const labor_law_countryOpen = !this.state.labor_law_countryOpen;
                this.setState({labor_law_countryOpen});
                break;
            case 'tax_countryOpen':
                const tax_countryOpen = !this.state.tax_countryOpen;
                this.setState({tax_countryOpen});
                break;
            case 'social_insurance_countryOpen':
                const social_insurance_countryOpen = !this.state.social_insurance_countryOpen;
                this.setState({social_insurance_countryOpen});
                break;
            case 'potential_trapsOpen':
                const potential_trapsOpen = !this.state.potential_trapsOpen;
                this.setState({potential_trapsOpen});
                break;
            default:
                break;
        }
    }

    validateGlobal() {
        const checkArr = [(this.state.scoreSet['labor_law_country'] ? this.state.scoreSet['labor_law_country'].id === this.state.context['country'] : false), (this.state.scoreSet['tax_country'] ? this.state.scoreSet['tax_country'].id === this.state.context['taxes'] : false), (this.state.scoreSet['social_insurance_country'] ? this.state.scoreSet['social_insurance_country'].id === this.state.context['social_security'] : false)];
        const isTrue = this.countItemsTrue(checkArr);
        const {t} = this.props;
        let resultIcon;
        let resultTitle;
        let resultText;
        if (isTrue === checkArr.length) {
            resultIcon = faCheckCircle;
            resultTitle = t("pages.results.title2");
            resultText = t("pages.results.description2");
            this.setState({resultIcon});
            this.setState({resultTitle});
            this.setState({resultText});
        } else if (checkArr.length > isTrue && isTrue > 0) {
            resultIcon = faExclamationCircle;
            resultTitle = t("pages.results.title1");
            resultText = t("pages.results.description1");
            this.setState({resultIcon});
            this.setState({resultTitle});
            this.setState({resultText});
        } else {
            resultIcon = faBan;
            resultTitle = t("pages.results.title1");
            resultText = t("pages.results.description1");
            this.setState({resultIcon});
            this.setState({resultTitle});
            this.setState({resultText});
        }

        // const valid = (this.state.scoreSet['labor_law_country'] ? this.state.scoreSet['labor_law_country'].id === this.state.context['country'] : false)
        // && (this.state.scoreSet['tax_country'] ? this.state.scoreSet['tax_country'].id === this.state.context['taxes'] : false)
        // && this.state.scoreSet['social_insurance_country'] ? this.state.scoreSet['social_insurance_country'].id === this.state.context['social_security'] : false;
        // return valid ? faCheckCircle : faExclamationCircle;
    }

    countItemsTrue(arry) {
        let result = 0;
        for (let x = 0; arry.length >= x; x++) {
            if (arry[x] === true) {
                result++;
            }
        }
        return result;

    }

    _getOrganisations(postalcode) {
        DataService.getOrganisationByPostalCode(postalcode).then(_organisations => {
            const organisations = _organisations.map(organisation => {
                return organisation.locations.map((location, i) => {
                    return (
                        <div className="contact__partner" key={i}>
                            <p className="contact__partner__title">{organisation.name} - {location.name}</p>
                            <div className="contact__partner__address">
                                <p>{location.street}</p>
                                <p>{location.zipcode} {location.city}</p>
                            </div>
                            <div className="contact__partner__phone">
                                <a href={('tel:' + location.phoneNumber)}>{location.phoneNumber}</a>
                            </div>
                            <div className="contact__partner__email">
                                <a href={('mailto:' + location.email)}>{location.email}</a>
                            </div>
                        </div>
                    );
                });
            });
            this.setState({organisations});
        })
    }

    render() {
        const {t} = this.props;
        return (
            <ResultPageElm>
                <PageGrid>
                    <p className="text--center">Score</p>
                    <FontAwesomeIcon icon={this.state.resultIcon}/>
                    <h2>{this.state.resultTitle}</h2>
                    <p className="text--center">{this.state.resultText}</p>

                    <div className="">
                        <div className="checklist">
                            <div onClick={() => this.toggleState('labor_law_countryOpen')}>
                                <Accordion viewAll={this.state.labor_law_countryOpen ? 'true' : 'false'}
                                           valid={this.validateScoreSet('labor_law_country')}
                                           title={t("pages.results.arbeidsrecht")}
                                           description={this.state.scoreSet.labor_law_country_description_tc}/>
                            </div>
                            <div onClick={() => this.toggleState('tax_countryOpen')}>
                                <Accordion viewAll={this.state.tax_countryOpen ? 'true' : 'false'}
                                           valid={this.validateScoreSet('tax_country')}
                                           title={t("pages.results.socialeVerzekering")}
                                           description={this.state.scoreSet.social_insurance_country_description_tc}/>
                            </div>
                            <div onClick={() => this.toggleState('social_insurance_countryOpen')}>
                                <Accordion viewAll={this.state.social_insurance_countryOpen ? 'true' : 'false'}
                                           valid={this.validateScoreSet('social_insurance_country')}
                                           title={t("pages.results.belastingen")}
                                           description={this.state.scoreSet.tax_country_description_tc}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => this.toggleState('potential_trapsOpen')}>
                            <Accordion viewAll={this.state.potential_trapsOpen ? 'true' : 'false'} valid={'trap'}
                                       title={t("pages.results.valkuilen")}
                                       description={this.state.scoreSet.pitfalls_tc}/>
                        </div>
                    </div>
                    <div className="contact">
                        <p>{t('pages.results.advice')}:</p>
                        <div className="contact_organisations">
                            <CardLink title={t("pages.results.abvv.name")} description="" link={t("pages.results.abvv.url")}
                                      logo={t("pages.results.abvv.logo")}></CardLink>
                            <CardLink title={t("pages.results.aclvb.name")} description="" link={t("pages.results.aclvb.url")}
                                      logo={t("pages.results.aclvb.logo")}></CardLink>
                            <CardLink title={t("pages.results.acv.name")} description=""
                                      link={t("pages.results.acv.url")}
                                      logo={t("pages.results.acv.logo")}></CardLink>
                            <CardLink title="FNV" description="" link="https://www.fnv.nl/grensarbeiders"
                                      logo={'/images/fnv.svg'}></CardLink>
                        </div>

                    </div>
                    {/*<div className="navigation">*/}
                    {/*    <Row between="xs">*/}
                    {/*        <Col xs={12}>*/}
                    {/*            <div className="text-center">*/}
                    {/*                <Button fullWidth={true}>*/}
                    {/*                    <Link to="/mail" className="slide-up-fade-in">*/}
                    {/*                        <span>Mail mij de resultaten</span>*/}
                    {/*                        <FontAwesomeIcon icon={faEnvelope} />*/}
                    {/*                    </Link>*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        </Col>*/}
                    {/*    </Row>*/}
                    {/*</div>*/}
                </PageGrid>
            </ResultPageElm>
        );
    }
}

export default withTranslation()(ResultPage);
